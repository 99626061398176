import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import BlogPageContent from "../templates/Blog/index";
import PageHeader from "../components/PageHeader";

const blogPage = () => {
	return (
		<Fragment>
			<MetaTags>
				<title>N.Y.C.E. n Neat Cleaning Services | Home</title>
				<meta name="description" content="Brooklyn maid Service." />
			</MetaTags>
			<LayoutOne>
				<PageHeader
					bgImg={process.env.PUBLIC_URL + "/images/page_bg.jpg"}
					title="Our Blog"
				/>

				<BlogPageContent />
			</LayoutOne>
		</Fragment>
	);
};

export default blogPage;
