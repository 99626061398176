import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Slider from "../components/Slider/SliderOne";
import WelocmePromo from "../components/WelcomePromo/PromoContainer";
import Services from "../components/Services/home-one/ServiceContainer";
// import FunFact from '../components/FunFact/FunFactContainer'
import ChooseUs from "../components/WhyChooseUs/ChooseUs";
import Gallery from "../components/Gallery/Gallery";
import Team from "../components/Team/TeamContainer";
import PricingTable from "../components/PricingTable/PricingContainer";
// import Testimonial from '../components/Testimonials/home-one/TestimonialContent';
import Blog from "../components/Blog/home-one/BlogContainer";
import LogoContainer from "../components/BrandLogo/LogoContainer";

const Home = () => {
	return (
		<Fragment>
			<MetaTags>
				<title>	N.Y.C.E. & Neat Cleaning Services | Brooklyn's Most Reliable At home Cleaning Service		</title>
				<meta name="description" content="NYCE N Neat Cleaning Services. Brooklyn NY Sexy Maid Services"/>
			</MetaTags>
			<LayoutOne>
				<Slider />
				<WelocmePromo />
				<Services />

				<ChooseUs />
				<Gallery />
				<Team />
				<PricingTable />

				<Blog />
				<LogoContainer />
			</LayoutOne>
		</Fragment>
	);
};

export default Home;
