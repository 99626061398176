import React , {Component, Fragment } from 'react';   
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';


class ChooseUsTab extends Component{
	render(){

		return(
			<Fragment>
				<div className="base_header_2">
					<h3>Frequently Asked Questions</h3>
				</div>
				<Accordion preExpanded={['a']}>
					<AccordionItem uuid="a">
						<AccordionItemHeading>
							<AccordionItemButton>
								Do I have to be home when the cleaner comes?
							</AccordionItemButton>
						</AccordionItemHeading>
						
						<AccordionItemPanel>
						Yes. how else do we get in? Arrangements have to be set up in advance to give us access to property if you do not want to be present while we work.
						</AccordionItemPanel>
					</AccordionItem>

					<AccordionItem>
						<AccordionItemHeading>
							<AccordionItemButton>
								How often should I have my home cleaned?
							</AccordionItemButton>
						</AccordionItemHeading>

						<AccordionItemPanel>
							You should have a deep cleaning once a month, with weekly touch ups.
						</AccordionItemPanel>
					</AccordionItem>

					<AccordionItem>
						<AccordionItemHeading>
							<AccordionItemButton>
								What is a walk-through?
							</AccordionItemButton>
						</AccordionItemHeading>

						<AccordionItemPanel>
						Upon our first contact with a new client (especially with commercial jobs or large homes) one of the first things we do is a walkthrough of your property. This is done to give you a more accurate cost estimate for your job. Management also conducts walkthroughs upon the completion of certain jobs



						</AccordionItemPanel>
					</AccordionItem>
				</Accordion>
			</Fragment>
		)
	}
};

export default ChooseUsTab;
