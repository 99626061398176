import React from 'react'; 
 
function TestimonialItem(props) {
    return (
 
        <div className="item" key={props.key}>
            <div className="testimonial-box">

                <div className="testi_images">  
                    <img src={process.env.PUBLIC_URL + "/images/" + props.authorThumb}  alt="img"/>
                </div>
                <div className="cus-title">
                    <span className="tes-nam">{props.author}</span>
                    <span className="tes-degree">{props.designation}</span>
                </div>
                <div className="testimonial_content"> 
                    <img src={process.env.PUBLIC_URL + "/images/comma.png"}  alt="img"/>
                    <p>{props.quote}</p>
                </div>
            </div>
        </div> 

    );
}

export default TestimonialItem;