import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Contact from "../templates/Contact/index";
import PageHeader from "../components/PageHeader";

const ContactPage = () => {
	return (
		<Fragment>
			<MetaTags>
				<title>N.Y.C.E. n Neat Cleaning Services | Contact Us</title>
				<meta
					name="description"
					content="Contact Page for NYCE and NEAT Cleaning Services Brooklyn NY."
				/>
			</MetaTags>

			<LayoutOne>
				<PageHeader
					bgImg={process.env.PUBLIC_URL + "/images/page_bg.jpg"}
					title="Contact Us"
				/>

				<Contact />
			</LayoutOne>
		</Fragment>
	);
};

export default ContactPage;
