import React from 'react'; 

function LogoItem(props) {
    return (
        <div className="client-box">  
            <img src={process.env.PUBLIC_URL + "/images/" + props.logoSrc}  alt="img"/>
        </div>
    );
}

export default LogoItem;