import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Gallery from "../components/Gallery/Gallery";
import PageHeader from "../components/PageHeader";

const galleryPage = () => {
	return (
		<Fragment>
			<MetaTags>
				<title>N.Y.C.E. n Neat Cleaning Services | Home</title>
				<meta name="description" content="Cleaning gallery." />
			</MetaTags>
			<LayoutOne>
				<PageHeader
					bgImg={process.env.PUBLIC_URL + "/images/page_bg.jpg"}
					title="Our Gallery"
				/>

				<Gallery />
			</LayoutOne>
		</Fragment>
	);
};

export default galleryPage;
